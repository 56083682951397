@import 'pages/pages_common';

.error_page_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .decorate_element img {
    width: 100%;
    max-width: rem-calc(425);
  }

  .dialog {
    padding: rem-calc(40 20 0 20);
    text-align: center;

    h1 {
      margin: rem-calc(0 0 15 0);
      line-height: 1;
      font: {
        size: rem-calc(40);
        weight: 400;
      }
    }

    a {
      display: inline-block;
      color: $green;
      line-height: 1;
      font-size: rem-calc(14);
      text-decoration: none;

      &.previous_page_link {
        position: relative;
        padding: rem-calc(12 5 10 15);
      }

      &.home_page_link {
        padding: rem-calc(12 20 10);
      }
    }
  }

  @include breakpoint(medium up) {
    flex-wrap: nowrap;
    height: calc(100vh - 64px);

    .decorate_element {
      flex: 0 0 50%;
      max-width: 50%;

      img {
        max-width: 100%;
      }
    }

    .dialog {
      flex: 0 0 60%;
      max-width: 60%;
      position: relative;
      margin-right: rem-calc(-100);
      padding: rem-calc(40 0 40 40);
      text-align: left;

      h1 {
        font: {
          size: rem-calc(54);
        }
      }

      p {
        margin: rem-calc(0 0 20 0);
        font: {
          size: rem-calc(24);
        }
      }

      a {
        font: {
          size: rem-calc(16);
        }

        &.previous_page_link {
          padding: rem-calc(17 35 15 20);
        }

        &.home_page_link {
          padding: rem-calc(17 35 15);
        }
      }
    }
  }

  @include breakpoint(large up) {
    .decorate_element {
      flex: 0 0 auto;
      max-width: rem-calc(520);
    }

    .dialog {
      flex: 0 0 auto;
      max-width: rem-calc(625);


      h1 {
        font: {
          size: rem-calc(74);
        }
      }

      p {
        margin-bottom: rem-calc(40);
        line-height: 1.1;
        font: {
          size: rem-calc(34);
        }
      }


    }
  }

  @include breakpoint(xxlarge up) {
   .decorate_element {
      max-width: rem-calc(822);
    }

   .dialog {
      margin-right: rem-calc(-250);
    }
  }
}
